import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import CategoriesList from 'pages/categories/List';
import CategoriesView from 'pages/categories/View';
import CategoriesAdd from 'pages/categories/Add';
import CategoriesEdit from 'pages/categories/Edit';
import CoursesList from 'pages/courses/List';
import CoursesView from 'pages/courses/View';
import CoursesAdd from 'pages/courses/Add';
import CoursesEdit from 'pages/courses/Edit';
import LessonmaterialsList from 'pages/lessonmaterials/List';
import LessonmaterialsView from 'pages/lessonmaterials/View';
import LessonmaterialsAdd from 'pages/lessonmaterials/Add';
import LessonmaterialsEdit from 'pages/lessonmaterials/Edit';
import LessonsList from 'pages/lessons/List';
import LessonsView from 'pages/lessons/View';
import LessonsAdd from 'pages/lessons/Add';
import LessonsEdit from 'pages/lessons/Edit';
import UsersList from 'pages/users/List';
import UsersView from 'pages/users/View';
import UsersAdd from 'pages/users/Add';
import UsersEdit from 'pages/users/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
import PurchaseList from 'pages/purchases/List';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* categories pages routes */}
				<Route path="/categories" element={<CategoriesList />} />
				<Route path="/categories/:fieldName/:fieldValue" element={<CategoriesList />} />
				<Route path="/categories/index/:fieldName/:fieldValue" element={<CategoriesList />} />
				<Route path="/categories/view/:pageid" element={<CategoriesView />} />
				<Route path="/categories/add" element={<CategoriesAdd />} />
				<Route path="/categories/edit/:pageid" element={<CategoriesEdit />} />

				{/* courses pages routes */}
				<Route path="/courses" element={<CoursesList />} />
				<Route path="/courses/:fieldName/:fieldValue" element={<CoursesList />} />
				<Route path="/courses/index/:fieldName/:fieldValue" element={<CoursesList />} />
				<Route path="/courses/view/:pageid" element={<CoursesView />} />
				<Route path="/courses/add" element={<CoursesAdd />} />
				<Route path="/courses/edit/:pageid" element={<CoursesEdit />} />

				{/* lessonmaterials pages routes */}
				<Route path="/lessonmaterials" element={<LessonmaterialsList />} />
				<Route path="/lessonmaterials/:fieldName/:fieldValue" element={<LessonmaterialsList />} />
				<Route path="/lessonmaterials/index/:fieldName/:fieldValue" element={<LessonmaterialsList />} />
				<Route path="/lessonmaterials/view/:pageid" element={<LessonmaterialsView />} />
				<Route path="/lessonmaterials/add" element={<LessonmaterialsAdd />} />
				<Route path="/lessonmaterials/edit/:pageid" element={<LessonmaterialsEdit />} />

				{/* lessons pages routes */}
				<Route path="/lessons" element={<LessonsList />} />
				<Route path="/lessons/:fieldName/:fieldValue" element={<LessonsList />} />
				<Route path="/lessons/index/:fieldName/:fieldValue" element={<LessonsList />} />
				<Route path="/lessons/view/:pageid" element={<LessonsView />} />
				<Route path="/lessons/add" element={<LessonsAdd />} />
				<Route path="/lessons/edit/:pageid" element={<LessonsEdit />} />

				{/* purchase routes */}
				<Route path='/purchases' element={<PurchaseList/>} />


				{/* users pages routes */}
				<Route path="/users" element={<UsersList />} />
				<Route path="/users/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/index/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/view/:pageid" element={<UsersView />} />
				<Route path="/users/add" element={<UsersAdd />} />
				<Route path="/users/edit/:pageid" element={<UsersEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
